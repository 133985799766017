import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TinderHeader from "./tinder-components/Header";
import TinderCards from "./tinder-components/Cards";
import SwipeButtons from "./tinder-components/SwipeButtons";
import Story from "./tinder-components/Story";
import { checkMotionPermission } from "./tinder-components/Shake";
import Nice from "./tinder-components/Nice";

function App() {
  checkMotionPermission();

  return (
    <>
      <div id="animate"></div>
      <Router>
        <Routes>
          <Route
            path="/sea"
            element={
              <div
                className="container"
                style={{
                  width: "100vw",
                  height: "100vh",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  color: "#f9f9f9",
                  backgroundImage: `url(${process.env.PUBLIC_URL}/imgs/lp_image.jpg)`,
                }}
              >
                <h1>{`We <3 J Wu!`}</h1>
              </div>
            }
          />
          <Route
            path="/nice"
            element={<Nice />
            }
          />
          <Route
            path="/30"
            element={
              <>
                <TinderHeader darkMode={true} backButton="/" />
                <Story />
                <SwipeButtons darkMode={true} />
              </>
            }
          />
          <Route
            path="/"
            element={
              <>
                <TinderHeader />
                <TinderCards />
                <SwipeButtons />
              </>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
