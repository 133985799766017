import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";

export default function Nice() {
  const [video, setVideo] = useState("vid7");
  const niceVideos = ["vid4", "vid5", "vid6", "vid8", "vid9"];

  const shake = (event) => {
    if (
      event.rotationRate.alpha > 256 ||
      event.rotationRate.beta > 256 ||
      event.rotationRate.gamma > 256
    ) {
      setVideo(niceVideos[Math.floor(Math.random() * niceVideos.length)]);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      window.addEventListener("devicemotion", shake);
    }, 1500);
  }, []);

  return (
    <div
      className="container"
      style={{
        width: "100vw",
        height: "100vh",
        color: "#f9f9f9",
      }}
    >
      <ReactPlayer
        className="react-player fixed-bottom"
        url={`${process.env.PUBLIC_URL}/videos/${video}-audio.mp4`}
        width="100%"
        height="100%"
        playing={true}
        playsinline={true}
        volume={1}
        loop={true}
        controls={true}
      />
    </div>
  );
}
