export async function checkMotionPermission() {
  // Any browser using requestPermission API
  if (typeof DeviceOrientationEvent.requestPermission === "function") {
    // If previously granted, user will see no prompts and listeners get setup right away.
    // If error, we show special UI to the user.
    // FYI, "requestPermission" acts more like "check permission" on the device.
    await DeviceOrientationEvent.requestPermission()
      .then((permissionState) => {
        if (permissionState == "granted") {
          // Hide special UI; no longer needed
          // btn_reqPermission.style.display = "none"
          setMotionListeners();
        }
      })
      .catch((error) => {
        console.log("Error getting sensor permission: %O", error);
        // Show special UI to user, suggesting they should allow motion sensors. The tap-or-click on the button will invoke the permission dialog.
        // btn_reqPermission.style.display = "block"
      });

    // All other browsers
  } else {
    setMotionListeners();
  }
}

export async function setMotionListeners() {
  // ORIENTATION LISTENER
  await window.addEventListener("orientation", (event) => {
    console.log("Device orientation event: %O", event);
  });
}
