import React, { useEffect, useState } from "react";
import "./Cards.css";

import { PICS } from "./CardsHelper";
import TinderCard from "react-tinder-card";
import { useNavigate } from "react-router-dom";
import ShakeyShakey, { shake } from "./ShakeyShakey";
import { Box, Button, CircularProgress } from "@mui/material";
import { checkMotionPermission } from "./Shake";

export default function Cards() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [emptyCards, setEmptyCards] = useState(false);
  const [eventAdded, setEventAdded] = useState(false);

  useEffect(() => {
    setTimeout(function () {
      setIsLoading(false);
    }, 5000);
  }, []);

  const shakeEvent = (event) => {
    if (
      event.rotationRate.alpha > 256 ||
      event.rotationRate.beta > 256 ||
      event.rotationRate.gamma > 256
    ) {
      shakeEventDidOccur();
    }
  };

  function shakeEventDidOccur() {
    shake();
    setTimeout(() => {
      window.removeEventListener("devicemotion", shakeEvent);
      navigate("/nice");
    }, 1200);
  }

  const onCardLeftScreen = (url) => {
    if (url === PICS[0].url) {
      if (!eventAdded) {
        window.addEventListener("devicemotion", shakeEvent);
      }

      setTimeout(() => {
        setEmptyCards(true);
        setEventAdded(true);
      }, 2000);
    }
  };

  return (
    <>
      {isLoading && (
        <Box sx={{ display: "flex", justifyContent: "center" , alignItems: "center", height: "50vh"}}>
          <CircularProgress />
        </Box>
      )}

      <div
        className="cardContainer"
        style={{
          opacity: isLoading ? "0" : "1",
          transition: "all .2s",
          visibility: isLoading ? "hidden" : "visible",
        }}
      >
        <ShakeyShakey />

        {emptyCards ? (
          <Button color="secondary" onClick={() => checkMotionPermission()}>
            Dare to click?
          </Button>
        ) : (
          PICS.map((person) => (
            <TinderCard
              key={person.url}
              className="swipe"
              onCardLeftScreen={() => onCardLeftScreen(person.url)}
              preventSwipe={["up", "down"]}
            >
              <div
                className="card"
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL}/imgs/${person.url})`,
                }}
              >
                <h3>{person.name}</h3>
              </div>
            </TinderCard>
          ))
        )}
      </div>
    </>
  );
}
