import React, { useState } from "react";
import ModalVideo from "react-modal-video";

import ReplayIcon from "@mui/icons-material/Replay";
import CloseIcon from "@mui/icons-material/Close";
import StarRateIcon from "@mui/icons-material/StarRate";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FlashOnIcon from "@mui/icons-material/FlashOn";

import "./SwipeButtons.css";
import { IconButton } from "@mui/material";

const SwipeButtons = ({ darkMode }) => {
  const [isOpen1, setOpen1] = useState(false);
  const [isOpen2, setOpen2] = useState(false);
  const [isOpen3, setOpen3] = useState(false);
  const [isOpen4, setOpen4] = useState(false);
  const [isOpen5, setOpen5] = useState(false);

  return (
    <>
      <ModalVideo
        channel="custom"
        url={`${process.env.PUBLIC_URL}/videos/vid1-audio.mp4`}
        isOpen={isOpen1}
        onClose={() => setOpen1(false)}
      />

      <ModalVideo
        channel="custom"
        url={`${process.env.PUBLIC_URL}/videos/vid2-audio.mp4`}
        isOpen={isOpen2}
        onClose={() => setOpen2(false)}
      />

      <ModalVideo
        channel="custom"
        url={`${process.env.PUBLIC_URL}/videos/vid3-audio.mp4`}
        isOpen={isOpen3}
        onClose={() => setOpen3(false)}
      />

      <ModalVideo
        channel="custom"
        url={`${process.env.PUBLIC_URL}/videos/vid22-audio.mp4`}
        isOpen={isOpen4}
        onClose={() => setOpen4(false)}
      />

      <ModalVideo
        channel="custom"
        url={`${process.env.PUBLIC_URL}/videos/vid33-audio.mp4`}
        isOpen={isOpen5}
        onClose={() => setOpen5(false)}
      />

      <div className={darkMode ? "swipeButtonsDark" : "swipeButtons"}>
        <IconButton
          className="swipeButtons__repeat"
          onClick={() => setOpen4(true)}
        >
          <ReplayIcon fontSize="large" />
        </IconButton>
        <IconButton
          className="swipeButtons__left"
          onClick={() => setOpen5(true)}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
        <IconButton
          className="swipeButtons__star"
          onClick={() => setOpen1(true)}
        >
          <StarRateIcon fontSize="large" />
        </IconButton>
        <IconButton
          className="swipeButtons__right"
          onClick={() => setOpen2(true)}
        >
          <FavoriteIcon fontSize="large" />
        </IconButton>
        <IconButton
          className="swipeButtons__lightning"
          onClick={() => setOpen3(true)}
        >
          <FlashOnIcon fontSize="large" />
        </IconButton>
      </div>
    </>
  );
};

export default SwipeButtons;
