import React from "react";
import "./Header.css";

import PersonIcon from "@mui/icons-material/Person";
import ForumIcon from "@mui/icons-material/Forum";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import { makeItRain } from "./EmojiRain";

export default function Header({ darkMode, backButton }) {
  const navigate = useNavigate();
  const location = window.location;

  if (location.pathname.includes("30")) {
    document.body.style.backgroundColor = "#121212";
  } else {
    document.body.style.backgroundColor = "#f9f9f9";
  }

  return (
    <>
      <div className={darkMode ? "headerDark" : "header"}>
        {backButton ? (
          <IconButton onClick={() => navigate(backButton)}>
            <ArrowBackIosIcon fontSize="large" className="header__icon" />
          </IconButton>
        ) : (
          <IconButton
            onClick={() =>
              (window.location.href =
                "https://www.alcove.place/jonathanwu")
            }
          >
            <PersonIcon className="header__icon" fontSize="large" />
          </IconButton>
        )}

        <IconButton onClick={() => makeItRain()}>
          <h1 className="header__logo">💩</h1>
        </IconButton>

        <IconButton onClick={() => navigate("/30")}>
          <ForumIcon className="header__icon" fontSize="large" />
        </IconButton>
      </div>
    </>
  );
}
