import gsap from "gsap";
import "./ShakeyShakey.css";

let numPoints = 10;
let delayPointsMax = 0.3;
let delayPerPath = 0.25;
let isOpened = false;
let initialized = false;
let pointsDelay = [];
let allPoints = [];
let tl;
let numPaths;
let paths;

function init() {
  paths = document.querySelectorAll(".shape-overlays__path");
  numPaths = paths.length;

  tl = gsap.timeline({
    onUpdate: render,
    defaults: {
      ease: "power2.inOut",
      duration: 0.9,
    },
  });

  for (let i = 0; i < numPaths; i++) {
    let points = [];
    allPoints.push(points);
    for (let j = 0; j < numPoints; j++) {
      points.push(100);
    }
  }
}

export function shake() {
  if (!initialized) {
    initialized = true;
    init();
  }

  if (!tl.isActive()) {
    isOpened = !isOpened;
    toggle();
  }
}

function toggle() {
  tl.progress(0).clear();

  for (let i = 0; i < numPoints; i++) {
    pointsDelay[i] = Math.random() * delayPointsMax;
  }

  for (let i = 0; i < numPaths; i++) {
    let points = allPoints[i];
    let pathDelay = delayPerPath * (isOpened ? i : numPaths - i - 1);

    for (let j = 0; j < numPoints; j++) {
      let delay = pointsDelay[j];
      tl.to(
        points,
        {
          [j]: 0,
        },
        delay + pathDelay
      );
    }
  }
}

function render() {
  for (let i = 0; i < numPaths; i++) {
    let path = paths[i];
    let points = allPoints[i];

    let d = "";
    d += isOpened ? `M 0 0 V ${points[0]} C` : `M 0 ${points[0]} C`;

    for (let j = 0; j < numPoints - 1; j++) {
      let p = ((j + 1) / (numPoints - 1)) * 100;
      let cp = p - ((1 / (numPoints - 1)) * 100) / 2;
      d += ` ${cp} ${points[j]} ${cp} ${points[j + 1]} ${p} ${points[j + 1]}`;
    }

    d += isOpened ? ` V 100 H 0` : ` V 0 H 0`;
    path.setAttribute("d", d);
  }
}

export default function ShakeyShakey() {
  return (
    <svg
      className="shape-overlays"
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
    >
      <defs>
        <linearGradient id="gradient1" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor="#00c99b" />
          <stop offset="100%" stopColor="#ff0ea1" />
        </linearGradient>
        <linearGradient id="gradient2" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor="#ffd392" />
          <stop offset="100%" stopColor="#ff3898" />
        </linearGradient>
        <linearGradient id="gradient3" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor="#110046" />
          <stop offset="100%" stopColor="#32004a" />
        </linearGradient>
      </defs>
      <path className="shape-overlays__path" fill="url(#gradient1)"></path>
      <path className="shape-overlays__path" fill="url(#gradient2)"></path>
      <path className="shape-overlays__path" fill="url(#gradient3)"></path>
    </svg>
  );
}
