export const PICS = [
  {
    name: "Truly Jonathan",
    url: "img1.jpg",
  },
  {
    name: "NASA J Wu",
    url: "img2.jpg",
  },
  {
    name: "Build a Wall J Wu",
    url: "img3.jpg",
  },
  {
    name: "Doordash J Wu",
    url: "img4.jpg",
  },
  {
    name: "Last of Us",
    url: "img5.jpg",
  },
  {
    name: "Joanna Wu",
    url: "img6.jpg",
  },
  {
    name: "Guitar Games J Wu",
    url: "img7.jpg",
  },
  {
    name: "Duck?",
    url: "img10.jpg",
  },
  {
    name: "Second Amendment J Wu",
    url: "img13.jpg",
  },
  {
    name: "Colonoscopy J Wu",
    url: "img21.jpg",
  },
  {
    name: "J Wu cooks!",
    url: "img22.jpg",
  },
  {
    name: "Sherlock J Wu",
    url: "img23.jpg",
  },
  {
    name: "Minnie J Wu",
    url: "img24.jpg",
  },
  {
    name: "Blink 182 J Wu",
    url: "img28.jpg",
  },
  {
    name: "Pumpkin J Wu",
    url: "img29.jpg",
  },
  {
    name: "Doge J Wu",
    url: "img30.jpg",
  },
  {
    name: "Hot J Wu",
    url: "img31.jpg",
  },
  {
    name: "HS J Wu",
    url: "img32.jpg",
  },
  {
    name: "Canadian J Wu",
    url: "img33.jpg",
  },
  {
    name: "Opera J Wu",
    url: "img34.jpg",
  },
  {
    name: "Price is Right J Wu",
    url: "img36.jpg",
  },
  {
    name: "Jimmy O Yang",
    url: "img37.jpg",
  },
  {
    name: "Where's J Wu",
    url: "img38.jpg",
  },
  {
    name: "Thumbs up J Wu",
    url: "img39.jpg",
  },
  {
    name: "J Wu with fav drink",
    url: "img40.jpg",
  },
  {
    name: "J Wu with Bird",
    url: "img41.jpg",
  },
  {
    name: "Tub J Wu",
    url: "img42.jpg",
  },
  {
    name: "Bubbly J Wu",
    url: "img43.jpg",
  },
  {
    name: "Unsure J Wu",
    url: "img44.jpg",
  },
  {
    name: "Traveller J Wu",
    url: "img45.jpg",
  },
  {
    name: "V. Cute J Wu",
    url: "img46.jpg",
  },
  {
    name: "Cute J Wu",
    url: "img47.jpg",
  },
  {
    name: "Almost Shirtless J Wu",
    url: "img48.jpg",
  },
  {
    name: "Rice Cooker J Wu",
    url: "img49.jpg",
  },
  {
    name: "Potato Phone J Wu",
    url: "img50.jpg",
  },
  {
    name: "J Wu with Girl",
    url: "img51.jpg",
  },
  {
    name: "I think J Wu",
    url: "img52.jpg",
  },
  {
    name: "Nature & J Wu",
    url: "img54.jpg",
  },
  {
    name: "Jonathan and Birdman",
    url: "img55.jpg",
  },
  {
    name: "J Wu Take 2",
    url: "img56.jpg",
  },
  {
    name: "J Wu with friend",
    url: "img57.jpg",
  },
  {
    name: "Gothic J Wu",
    url: "img58.jpg",
  },
  {
    name: "White J Wu",
    url: "img59.jpg",
  },
  {
    name: "Homeless J Wu",
    url: "img60.jpg",
  },
  {
    name: "Why J Wu",
    url: "img61.jpg",
  },
  {
    name: "J Wu in a Relationship",
    url: "img62.jpg",
  },
  {
    name: "Chinese Brothers",
    url: "img63.jpg",
  },
  {
    name: "BeReal J Wu",
    url: "img64.jpg",
  },
  {
    name: "Face mask J Wu",
    url: "img65.jpg",
  },
  {
    name: "Beautiful J Wu",
    url: "img66.jpg",
  },
];
